import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

interface DetachFileParams {
  versionId: string;
  fileId: string;
  toolPurpose: string;
}

export const useDetachAgentFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const detachFile = async ({ versionId, fileId, toolPurpose }: DetachFileParams) => {
    setLoading(true);
    setError(null);

    try {
      const token = loadToken() || "";
      if (!token || token === "") {
        throw new Error("Session expired");
      }

      const response = await fetch(
        `${API_URL}/api/v1/agents/versions/${versionId}/files/${fileId}?tool_purpose=${toolPurpose}`,
        {
          method: "DELETE",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to detach file");
      }

      return true;
    } catch (err: any) {
      setError(err.message);
      toast({
        title: "Error detaching file",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { detachFile, loading, error };
};
