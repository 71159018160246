import { useToast } from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";
import { File } from "../client/types";

interface AttachedFile {
  id: string;
  agent_version_id: string;
  file_id: string;
  tool_purpose: string;
  file: File;
  inserted_at: string;
  updated_at: string;
}

export const useListAgentVersionFiles = (versionId: string, toolPurpose: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [files, setFiles] = useState<AttachedFile[]>([]);
  const toast = useToast();

  const listVersionFiles = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token || token === "") {
        throw new Error("Session expired");
      }

      const response = await fetch(
        `${API_URL}/api/v1/agents/versions/${versionId}/files?tool_purpose=${toolPurpose}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 204) {
        setFiles([]);
        return [];
      }

      if (!response.ok) {
        throw new Error("Failed to get version files");
      }

      const data = await response.json();
      setFiles(Array.isArray(data) ? data : [data]); // Handle both array and single object responses
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to get version files",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [versionId, toolPurpose, toast]);

  useEffect(() => {
    listVersionFiles();
  }, [listVersionFiles]);

  return { loading, error, files, listVersionFiles };
};
