import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";
interface AttachFileParams {
    versionId: string;
    fileId: string;
    toolPurpose: string;
}

export const useAttachAgentFile = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const toast = useToast();
    const token = loadToken();

    const attachFile = async ({ versionId, fileId, toolPurpose }: AttachFileParams) => {
        setLoading(true);
        setError(null);

        try {

            const response = await fetch(
                `${API_URL}/api/v1/agents/versions/${versionId}/files/${fileId}`,
                {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        tool_purpose: toolPurpose
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to attach file');
            }

            const data = await response.json();
            return data;
        } catch (err: any) {
            setError(err.message);
            toast({
                title: "Error attaching file",
                description: err.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { attachFile, loading, error };
};
