import {
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { UploadFileButton } from "./KnowledgeBaseUploadButton";
import { FiTrash2 } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { File } from "../../client/types";
import { useDeleteAgentFile } from "../../hooks/useDeleteAgentFile";

export const KnowledgeBaseSourcesTable = (
  {
    files,
    removeFile,
    addFile,
  }: { 
    files: File[]; 
    removeFile: (fileId: string) => void;
    addFile: (file: any) => void;
  },
  props: TableProps
) => {
  const { deleteFile, loading, error } = useDeleteAgentFile();
  const [fileToDelete, setFileToDelete] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const params = useParams();
  const agent_id = params.id ?? "";

  const confirmDelete = (fileId: string) => {
    setFileToDelete(fileId);
    onOpen();
  };

  const handleDelete = async () => {
    if (fileToDelete) {
      const success = await deleteFile(agent_id, fileToDelete);
      if (success) {
        removeFile(fileToDelete); // Notify parent to remove file from the list
        toast({
          title: "File deleted.",
          description: "The file has been successfully removed.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      } else if (error) {
        toast({
          title: "Error deleting file.",
          description: "Could not delete the file. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setFileToDelete(null);
      onClose();
    }
  };

  return (
    <>
      <Stack spacing={4}>
        <Table {...props}>
          <Thead>
            <Tr>
              <Th>
                <HStack spacing="3">
                  <Text>Name</Text>
                </HStack>
              </Th>
              <Th>Description</Th>
              <Th>Last Modified</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {files.map((file) => (
              <Tr key={file.id}>
                <Td>
                  <Box>
                    <Text fontWeight="medium">{file.name}</Text>
                  </Box>
                </Td>
                <Td>
                  <Text color="fg.muted">{file.description}</Text>
                </Td>
                <Td>
                  <Text color="fg.muted">{file.uploaded_at}</Text>
                </Td>
                <Td>
                  <IconButton
                    aria-label="Delete file"
                    icon={<FiTrash2 />}
                    variant="ghost"
                    size="sm"
                    onClick={() => confirmDelete(file.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Box>
          <UploadFileButton agent_id={agent_id} addFile={addFile} />
        </Box>
      </Stack>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this file? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              mr={3}
              variant="outline"
              isDisabled={loading}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              isLoading={loading}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
