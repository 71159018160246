import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";

export const useEnableTool = () => {
  const toast = useToast();
  const enableTool = useCallback(async ({ versionId, toolId }: { versionId: string; toolId: string }) => {
    // Dummy implementation that always returns true
    toast({
      title: "Tool enabled successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    return true;
  }, [toast]);

  return { enableTool };
};
