import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";

export const useDisableTool = () => {
  const toast = useToast();
  const disableTool = useCallback(async ({ versionId, toolId }: { versionId: string; toolId: string }) => {
    // Dummy implementation that always returns true
    toast({
      title: "Tool disabled successfully",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    return true;
  }, [toast]);

  return { disableTool };
};
