import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

interface DeleteUserParams {
  email: string;
  organization_id: string;
}

export const useDeleteUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const deleteUser = useCallback(async ({ email, organization_id }: DeleteUserParams) => {
    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token) {
        throw new Error("Session expired");
      }

      const response = await fetch(
        `${API_URL}/api/v1/user/${encodeURIComponent(email)}?organization_id=${organization_id}&soft_delete=true`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Failed to delete user");
      }

      toast({
        title: "User deleted",
        description: "User has been deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      
      return true;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to delete user",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    } finally {
      setLoading(false);
    }
  }, [toast]);

  return { deleteUser, loading, error };
};
