import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Stack,
  StackDivider,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAgent } from "../../hooks/useGetAgent";
import { useGetAgentVersion } from "../../hooks/useGetAgentVersion";
import { useUpdateAgentVersion } from "../../hooks/useUpdateAgentVersion";
import { useGetEngine } from "../../hooks/useGetEngine";
import { useEnableTool } from "../../hooks/useEnableTool";
import { useDisableTool } from "../../hooks/useDisableTool";

import { AgentVersion, EngineTool } from "../../client/types";
import { MarkConfig } from "../Engines/MarkConfig";
import { AgentFilesTable } from "./AgentFilesTable";

export const VersionConfig = ({
  agentId,
  version_id,
  isOpen,
  onClose,
  isDeleteMode = false,
  onUpdatedAgentVersion,
}: {
  agentId: string;
  version_id: string;
  isOpen: boolean;
  onClose: () => void;
  isDeleteMode?: boolean;
  onUpdatedAgentVersion: (updatedAgentVersion: any) => void;
}) => {
  const { agent } = useGetAgent(agentId);

  const { engine } = useGetEngine(agent?.engine?.id || "");
  const [selectedTool, setSelectedTool] = useState<string>("");
  const { fetchedAgentVersion: AgentVersion, loading: getAgentVersionLoading } =
    useGetAgentVersion(agentId, version_id);
  const { updateAgentVersion, loading: updateAgentVersionLoading } =
    useUpdateAgentVersion();

  // State for version data
  const [versionName, setVersionName] = useState<string | undefined>("");
  const [versionDescription, setVersionDescription] = useState<
    string | undefined
  >("");
  const [instructions, setInstructions] = useState(
    AgentVersion?.instructions || {
      role: "",
      general_context: "",
      ask: "",
      examples: [],
    }
  );
  const [isDefault, setIsDefault] = useState<boolean | undefined>(false);
  const [enabledTools, setEnabledTools] = useState<string[]>([]);
  const [pendingToolChanges, setPendingToolChanges] = useState<string[]>([]);
  const [processingTools, setProcessingTools] = useState<string[]>([]);
  const { enableTool } = useEnableTool();
  const { disableTool } = useDisableTool();

  // State to track if any changes have been made
  const [isModified, setIsModified] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const toast = useToast();

  // Set initial values when AgentVersion is loaded
  useEffect(() => {
    if (AgentVersion) {
      setVersionName(AgentVersion.name);
      setVersionDescription(AgentVersion.description);
      setInstructions(
        AgentVersion.instructions || {
          role: "",
          general_context: "",
          ask: "",
          examples: [],
        }
      );
      setIsDefault(AgentVersion.is_default);
    }
  }, [AgentVersion]);

  // Initialize all tools as enabled when engine loads
  useEffect(() => {
    if (engine?.engine_tools) {
      setEnabledTools(engine.engine_tools.map(tool => tool.id));
    }
  }, [engine]);

  useEffect(() => {
    // Check if the current values differ from the initial values
    if (AgentVersion) {
      const hasChanges =
        versionName !== AgentVersion.name ||
        versionDescription !== AgentVersion.description ||
        JSON.stringify(instructions) !==
        JSON.stringify(AgentVersion.instructions) ||
        isDefault !== AgentVersion.is_default ||
        pendingToolChanges.length > 0;

      setIsModified(hasChanges);
    }
  }, [versionName, versionDescription, instructions, isDefault, AgentVersion, pendingToolChanges]);

  const handleDelete = () => {
    onClose();
    toast({
      title: "Version deleted.",
      description: `The version ${versionName} has been deleted successfully.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const applyToolChanges = async () => {
    setProcessingTools(pendingToolChanges);
    try {
      for (const toolId of pendingToolChanges) {
        if (enabledTools.includes(toolId)) {
          await disableTool({ versionId: version_id, toolId });
          setEnabledTools(prev => prev.filter(id => id !== toolId));
        } else {
          await enableTool({ versionId: version_id, toolId });
          setEnabledTools(prev => [...prev, toolId]);
        }
      }
      setPendingToolChanges([]);
    } catch (error) {
      toast({
        title: "Failed to update tool status",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setProcessingTools([]);
  };

  const handleSave = async () => {
    try {
      // If only tools were modified
      if (pendingToolChanges.length > 0 && 
          versionName === AgentVersion?.name &&
          versionDescription === AgentVersion?.description &&
          JSON.stringify(instructions) === JSON.stringify(AgentVersion?.instructions) &&
          isDefault === AgentVersion?.is_default) {
        await applyToolChanges();
        //onClose();
      } else {
        // If other fields were modified (with or without tools)
        const updatedVersion: AgentVersion = await updateAgentVersion({
          agent_id: agentId,
          version_id: version_id,
          name: versionName || "",
          description: versionDescription || "",
          is_default: isDefault || false,
          instructions,
        });
        if (pendingToolChanges.length > 0) {
          await applyToolChanges();
        }
        onUpdatedAgentVersion(updatedVersion);
        onClose();
      }
    } catch (error) {
      toast({
        title: "Failed to save version.",
        description: "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (!isOpen) return null;
  if (getAgentVersionLoading) {
    return (
      <Container 
        py={{ base: "4", md: "8" }} 
        height="100%"
        maxW="container.xl"
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <Center flex="1">
          <Spinner size="xl" />
          <Text ml={4}>Loading config for agent version...</Text>
        </Center>
      </Container>
    );
  } else {
    return (
      <Container 
        py={{ base: "4", md: "8" }} 
        height="100%"
        maxW="container.xl"
        display="flex"
        flexDirection="column"
      >
        {isDeleteMode ? (
          <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelRef}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Are you sure?
                </AlertDialogHeader>

                <AlertDialogBody>This action cannot be undone.</AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        ) : (
          <Stack spacing="5" height="100%" overflow="hidden">

            <Tabs 
              onChange={(index) => setSelectedTab(index)} 
              display="flex" 
              flexDirection="column" 
              height="100%"
            >
              <TabList>
                <Tab>Configurations</Tab>
                <Tab>Instructions</Tab>
                <Tab>Tools</Tab>
              </TabList>

              <TabPanels flex="1" overflow="auto">
                <TabPanel>
                  <Stack spacing="5" divider={<StackDivider />}>
                    <FormControl id="name">
                      <Stack
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "1.5", md: "8" }}
                        justify="space-between"
                      >
                        <FormLabel variant="inline">Version Name</FormLabel>
                        <Input
                          maxW={{ md: "3xl" }}
                          fontSize="sm"
                          value={versionName}
                          onChange={(e) => setVersionName(e.target.value)}
                        />
                      </Stack>
                    </FormControl>
                    <FormControl id="description">
                      <Stack
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "1.5", md: "8" }}
                        justify="space-between"
                      >
                        <FormLabel variant="inline">Version Description</FormLabel>
                        <Input
                          fontSize="sm"
                          maxW={{ md: "3xl" }}
                          value={versionDescription}
                          onChange={(e) => setVersionDescription(e.target.value)}
                        />
                      </Stack>
                    </FormControl>
                    <Stack spacing="5">
                      <Stack direction="row" justify="space-between">
                        <Box>
                          <Text textStyle="lg" fontWeight="bold" color="green.500">
                            Set as Default
                          </Text>
                          <Text color="fg.muted" textStyle="sm">
                            Make this version the default version for the agent.
                          </Text>
                        </Box>
                        <Switch
                          colorScheme="brand"
                          isChecked={isDefault}
                          onChange={() => setIsDefault(!isDefault)}
                        />
                      </Stack>

                      <Box>
                        <Text textStyle="lg" fontWeight="bold" mb={4}>
                          Available Tools
                        </Text>
                        <Stack spacing={4}>
                          {engine?.engine_tools?.map((tool: EngineTool) => (
                            <Stack key={tool.id} direction="row" justify="space-between" align="center">
                              <Box>
                                <Text fontWeight="medium">{tool.name}</Text>
                                <Text color="fg.muted" textStyle="sm">
                                  {tool.description || `Configure ${tool.name} settings`}
                                </Text>
                              </Box>
                              <Switch
                                colorScheme="brand"
                                isChecked={
                                  pendingToolChanges.includes(tool.id)
                                    ? !enabledTools.includes(tool.id)
                                    : enabledTools.includes(tool.id)
                                }
                                isDisabled={processingTools.includes(tool.id)}
                                onChange={() => {
                                  setPendingToolChanges(prev => 
                                    prev.includes(tool.id)
                                      ? prev.filter(id => id !== tool.id)
                                      : [...prev, tool.id]
                                  );
                                }}
                              />
                              {processingTools.includes(tool.id) && (
                                <Spinner size="sm" ml={2} />
                              )}
                            </Stack>
                          ))}
                        </Stack>
                      </Box>
                    </Stack>
                  </Stack>
                </TabPanel>

                <TabPanel>
                  <Box>
                    <Text textStyle="lg" fontWeight="medium">
                      Instructions
                    </Text>
                    <Text color="fg.muted" textStyle="sm">
                      Configure the agent's knowledge base.
                    </Text>
                  </Box>
                  <MarkConfig
                    version={AgentVersion}
                    setInstructions={setInstructions}
                  />
                </TabPanel>

                <TabPanel>
                  <Box>
                    <Text textStyle="lg" fontWeight="medium">
                      Tools
                    </Text>
                    <Text color="fg.muted" textStyle="sm">
                      Configure the tools available to this agent version.
                    </Text>
                  </Box>
                  <Box mt={4}>
                    <Select
                      placeholder="Select a tool"
                      value={selectedTool}
                      onChange={(e) => setSelectedTool(e.target.value)}
                    >
                      {engine?.engine_tools?.map((tool: EngineTool) => (
                        <option key={tool.id} value={tool.id}>
                          {tool.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box mt={4}>
                    {(selectedTool === "file_search" || selectedTool === "code_interpreter") && (
                      <AgentFilesTable 
                        agentId={agentId} 
                        versionId={version_id} 
                        toolPurpose={selectedTool}
                      />
                    )}
                    {selectedTool === "store_user_data" && (
                      <Box>
                        <Text>Store User Data Configuration</Text>
                        {/* Tool configuration form will be implemented here */}
                        <Button
                          mt={4}
                          colorScheme="brand"
                          onClick={() => {/* Tool specific save handler */}}
                        >
                          Save Configuration
                        </Button>
                      </Box>
                    )}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Box pt={4} borderTop="1px solid" borderColor="gray.200">
              {selectedTab !== 2 && (
                <Button
                  onClick={handleSave}
                  colorScheme="brand"
                  isDisabled={!isModified || updateAgentVersionLoading}
                  isLoading={updateAgentVersionLoading}
                  width="100%"
                >
                  Save {selectedTab === 0 ? 'Configurations' : 'Instructions'}
                </Button>
              )}
            </Box>
          </Stack>
        )}
      </Container>
    );
  }
};
