import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useState, useMemo } from "react";
import { useCreateUser } from "../../hooks/useCreateUser";

import { User } from "../../hooks/useListUsers";

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (user: User) => void;
}

export const AddUserModal = ({ isOpen, onClose, onSuccess }: AddUserModalProps) => {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [roleId, setRoleId] = useState<"USER" | "ADMIN">("USER");

  const { createUser, loading } = useCreateUser();
  const toast = useToast();
  const organization_id = localStorage.getItem("organization_id");

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = useMemo(() => {
    return email && fullName && password && isValidEmail(email);
  }, [email, fullName, password]);

  const handleSubmit = async () => {
    if (!isValidEmail(email)) {
      toast({
        title: "Invalid email",
        description: "Please enter a valid email address",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!organization_id) {
      toast({
        title: "Error",
        description: "Organization ID not found",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const result = await createUser({
      email,
      full_name: fullName,
      password,
      organization_id,
      phone_number: phoneNumber,
      role_id: roleId,
    });

    if (result) {
      onSuccess(result);
      onClose();
      // Reset form
      setEmail("");
      setFullName("");
      setPassword("");
      setPhoneNumber("");
      setRoleId("USER");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
              <FormControl isRequired isInvalid={email !== "" && !isValidEmail(email)}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {email !== "" && !isValidEmail(email) && (
                  <FormErrorMessage>Please enter a valid email address</FormErrorMessage>
                )}
              </FormControl>
            <FormControl isRequired>
              <FormLabel>Full Name</FormLabel>
              <Input
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Role</FormLabel>
              <Select
                value={roleId}
                onChange={(e) => setRoleId(e.target.value as "USER" | "ADMIN")}
              >
                <option value="USER">User</option>
                <option value="ADMIN">Admin</option>
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="purple"
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={!isFormValid}
          >
            Add User
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
