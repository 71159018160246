import { Box, Flex, Text, Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { AgentVersion } from "../../client/types";
import { useListAgentsVersions } from "../../hooks/useListAgentsVersions";
import { AgentVersionsTable } from "./AgentVersionsTable";
import { NewVersion } from "./NewVersion";

export const AgentVersions = ({
  id,
  agentName,
}: {
  id: string;
  agentName: string;
}) => {
  const agentId = id;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedVersion, setSelectedVersion] = useState<string | null>(null); // State for selected version ID
  const { agentVersions: fetchedAgentVersions, refetch } = useListAgentsVersions(agentId);
  const [agentVersions, setAgentVersions] = useState<AgentVersion[]>([]);

  // Reset state and fetch new versions when agent changes
  useEffect(() => {
    setIsEditing(false);
    setSelectedVersion(null);
    setAgentVersions([]);
    refetch();
  }, [id, refetch]);

  // Update versions when fetched data changes
  useEffect(() => {
    if (fetchedAgentVersions && fetchedAgentVersions.length > 0) {
      setAgentVersions(fetchedAgentVersions);
    }
  }, [fetchedAgentVersions]);

  const handleNewAgentVersion = (newAgentVersion: AgentVersion) => {
    setAgentVersions((prevVersions) => [...prevVersions, newAgentVersion]);
  };

  const handleUpdatedAgentVersion = async (updatedAgentVersion: AgentVersion) => {
    // If the version was made default, refetch all versions since this affects multiple versions
    if (updatedAgentVersion.is_default) {
      await refetch();
    } else {
      // For non-default changes, just update the single version
      setAgentVersions((prevVersions) =>
        prevVersions.map((version) =>
          version.version === updatedAgentVersion.version
            ? updatedAgentVersion
            : version
        )
      );
    }
  };

  return (
    <Flex direction="column" h="100%" w="100%" overflow="hidden">
      <Flex
        direction="column"
        py={4}
        px={6}
        borderBottom="1px solid #e2e8f0"
        gap={4}
      >
        <Flex justify="space-between" align="center" width="100%">
          <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink 
                onClick={() => setIsEditing(false)}
                fontSize="xl" 
                fontWeight="bold"
                color={isEditing ? "gray.500" : "black"}
                _hover={{
                  textDecoration: "none",
                  color: "brand.500"
                }}
              >
                Versions
              </BreadcrumbLink>
            </BreadcrumbItem>
            {isEditing && (
              <BreadcrumbItem isCurrentPage>
                <Text fontSize="xl" fontWeight="semibold" color="brand.500">
                  Edit Version
                </Text>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
          <NewVersion agentId={agentId} onNewAgentVersion={handleNewAgentVersion} />
        </Flex>
      </Flex>

      <Box flex="1" width="100%" overflow="auto">
        <AgentVersionsTable
          id={agentId}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          agentVersions={agentVersions}
          onUpdatedAgentVersion={handleUpdatedAgentVersion}
          agentName={agentName}
        />
      </Box>
    </Flex>
  );
};
