import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import { User } from "../../hooks/useListUsers";
import { useDeleteUser } from "../../hooks/useDeleteUser";
import { useRef, useState, useMemo } from "react";

const getRoleBadgeColor = (role: string) => {
  switch (role) {
    case 'SUPER_ADMIN':
      return 'green';
    case 'ADMIN':
      return 'yellow';
    default:
      return 'gray';
  }
};

interface UsersTableProps {
  users: User[];
  loading: boolean;
  error: string | null;
  onUserDeleted: (userId: string) => void;
}

export const UsersTable = ({ users, loading, error, onUserDeleted }: UsersTableProps) => {
  const currentUserEmail = useMemo(() => localStorage.getItem("email"), []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { deleteUser, loading: deleteLoading } = useDeleteUser();
  const organization_id = localStorage.getItem("organization_id") || "";

  const handleDelete = async (user: User) => {
    setSelectedUser(user);
    onOpen();
  };

  const confirmDelete = async () => {
    if (!selectedUser) return;

    const success = await deleteUser({
      email: selectedUser.email,
      organization_id,
    });

    if (success) {
      onUserDeleted(selectedUser.id);
    }
    onClose();
  };

  if (loading) {
    return (
      <Center height="70vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text ml={4}>Loading users...</Text>
      </Center>
    );
  }

  if (error || !users || users.length === 0) {
    return (
      <Center height="70vh">
        <Text color="gray.600">{error || "No users found"}</Text>
      </Center>
    );
  }

  return (
    <Box overflowY="auto">
      <Table>
        <Thead position="sticky" top={0} bg="white" zIndex={1}>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th>Phone</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td>
                <HStack>
                  <Text color="gray.600">{user.full_name}</Text>
                  {user.email === currentUserEmail && (
                    <Badge colorScheme="gray" fontSize="xs">
                      You
                    </Badge>
                  )}
                </HStack>
              </Td>
              <Td>
                <Text color="gray.600">{user.email}</Text>
              </Td>
              <Td>
                <Badge colorScheme={getRoleBadgeColor(user.user_organizations[0]?.role_id)}>
                  {user.user_organizations[0]?.role_id}
                </Badge>
              </Td>
              <Td>
                <Text color="gray.600">{user.phone_number || "-"}</Text>
              </Td>
              <Td>
                <HStack spacing="1">
                  <Tooltip label="Delete user">
                    <IconButton
                      icon={<FiTrash2 />}
                      variant="ghost"
                      colorScheme="red"
                      aria-label="Delete user"
                      onClick={() => handleDelete(user)}
                      isDisabled={user.user_organizations[0]?.role_id === "SUPER_ADMIN"}
                    />
                  </Tooltip>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete {selectedUser?.full_name}? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={deleteLoading}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmDelete}
                ml={3}
                isLoading={deleteLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
