import { Button, Flex, Heading, VStack, useDisclosure } from "@chakra-ui/react";
import { UsersTable } from "./UsersTable";
import { useRoleAuth } from "../../hooks/useRoleAuth";
import { AddUserModal } from "./AddUserModal";
import { useListUsers } from "../../hooks/useListUsers";
import { AddIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { User } from "../../hooks/useListUsers";

export const AdminSettings = () => {
  const { isSuperAdmin } = useRoleAuth();
  const organization = localStorage.getItem("organization");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { users: initialUsers, loading, error, listUsers } = useListUsers();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    // Only set initial users if we don't have any users yet
    if (initialUsers && users.length === 0) {
      setUsers(initialUsers);
    }
  }, [initialUsers, users.length]);

  const handleUserCreated = async (newUser: User) => {
    // Add the user immediately with temporary ID
    setUsers(prevUsers => [...prevUsers, newUser]);
    
    // Then refresh the list to get the real data
    setTimeout(async () => {
      const refreshedUsers = await listUsers();
      if (refreshedUsers) {
        // Find the new user in the refreshed list by email
        const realNewUser = refreshedUsers.find((u: User) => u.email === newUser.email);
        if (realNewUser) {
          // Replace the temporary user with the real one
          setUsers(prevUsers => 
            prevUsers.map((u: User) => u.id === newUser.id ? realNewUser : u)
          );
        }
      }
    }, 1000);
  };

  if (!isSuperAdmin()) {
    return null;
  }

  return (
    <Flex direction="column" minH="80vh" minW="135vh" maxH="100vh" overflow="auto">
      <Flex
        justify="space-between"
        align="center"
        p={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Heading size="xs" fontWeight="medium">
          {organization} Users
        </Heading>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="purple"
          variant="solid"
          size="sm"
          onClick={onOpen}
        >
          Add User
        </Button>
      </Flex>
      <VStack spacing={8} align="stretch" width="100%" p={4}>
        <Flex direction="column" width="100%">
          <UsersTable 
            users={users} 
            loading={loading} 
            error={error} 
            onUserDeleted={(userId) => {
              setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
            }}
          />
        </Flex>
      </VStack>
      <AddUserModal
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={handleUserCreated}
      />
    </Flex>
  );
};
