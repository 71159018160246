import { Flex } from "@chakra-ui/react";
import { AdminSettings } from "../components/Admin/AdminSettings";
import { SideBar } from "../components/Sidebar/SideBar";
import { useListAgents } from "../hooks/useListAgents";

export const Admin = () => {
  const { agents, loading } = useListAgents();

  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar agents={agents} agentsLoading={loading} />
      </Flex>
      <Flex flex="4" justify="center" direction="column">
        <AdminSettings />
      </Flex>
    </Flex>
  );
};
