import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { File as FileType } from "../../client/types";
import { useListAgentsFiles } from "../../hooks/useListAgentsFiles";
import { KnowledgeBaseSourcesTable } from "./KnowledgeBaseSourcesTable";
import { UploadFileButton } from "./KnowledgeBaseUploadButton";

export const KnowledgeBaseTable = ({ agent_id }: { agent_id: string }) => {
  const { files: initialFiles, error } = useListAgentsFiles(agent_id);
  const [files, setFiles] = useState<FileType[]>(initialFiles || []);

  useEffect(() => {
    if (initialFiles) {
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  const addFile = (file: FileType) => {
    setFiles((prevFiles) => [...prevFiles, file]);
  };

  const removeFile = (fileId: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
  };

  return (
    <Flex direction="column" maxH="40vh" minW="100%" overflowY="auto" >
      <Flex flex="1" p={4}>
        <Box flex="1">
          {error ? (
            <Text color="red.500">
              Failed to load files. Please try again later.
            </Text>
          ) : files.length > 0 ? (
            <KnowledgeBaseSourcesTable 
              files={files} 
              removeFile={removeFile}
              addFile={addFile}
            />
          ) : (
            <Box>
              <Text mb={4}>No files available. You can upload a new file.</Text>
              <UploadFileButton agent_id={agent_id} addFile={addFile} />
            </Box>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
